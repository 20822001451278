.footer-section {
  padding: 90px 0px 70px;
}
.cpyright {
  border-top: 1px solid #d6d6d6;
  padding: 25px 0px;
  text-align: center;
}
.copyright-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;

  color: #222222;
}
.copyright-text span {
  color: #1a8fe3;
}
.footer-nav-list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer-nav-list ul li a,
.footer-nav-list ul li {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 15px;
  text-transform: capitalize;
  color: #6b7177;
  text-decoration: none;
}
.footer-nav-list ul li :hover {
  color: #1a8fe3 ;
}
.footer-nav-list h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: #222222;
}
.footer-nav-list h3 span {
  color: #1a8fe3;
  font-weight: 700;
  font-size: 30px;
}
.footer-img-logo {
  // width: 193px;
  cursor: pointer;
}
.footer-p-text {
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  text-transform: capitalize !important;
  color: #6b7177 !important;
  margin-top: 20px !important;
  margin-bottom: 28px !important;
}
.footersocial-icon ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 25px 0px 0px 0px;
  border-top: 1px dashed #d6d6d6;
}
.footersocial-icon li {
  margin-right: 13px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  color: #6b7177;
  cursor: pointer;
}

.footersocial-icon li:first-child {
  cursor: default;
}

/** Get in touch */
.get-in-touch-inner {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  height: 412px;
  justify-content: space-between;
}
.get-in-touch-left {
  width: 58%;
}
.get-in-touch-left h3 {
  font-weight: 700;
  font-size: 50px;
  line-height: 65px;
  color: #ffffff;
  margin-bottom: 8px;
  margin-top: 0;
}
.get-in-touch-left span {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 1px;
  color: #ffffff;
}
.get-in-touch-btn {
  background: #fff !important;
  border-radius: 10px !important;
  color: #1a8fe3 !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 38px !important;
  padding: 17px 33px !important;
  text-decoration: none;
  text-transform: capitalize !important;
}
.footer-sec-bg {
  background: #eff7fd;
}
.video-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  left: 0px;
  top:0px;
  z-index: 2;
}
.video-wrapper video {
  object-fit: cover;
}

.video-content-wra {
  position: relative;
  z-index: 4;
}
.vid-gradient {
  background: linear-gradient(
    90deg,
    rgba(45, 45, 176, 0.8) 0%,
    rgba(44, 140, 244, 0.8) 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  left: 0px;
  top:0px;
}

@media (max-width: 959px) {
  .footer-nav ul li a {
    padding: 0.5rem 1rem;
  }
}
@media (max-width: 767px) {
  .get-in-touch-left {
    text-align: center;
    width: 100%;
    margin-bottom: 28px;
  }
  .get-in-touch-inner {
    align-items: center;
    display: flex;
    flex-flow: column;
    // height: 412px;
    justify-content: center;
  }
  .get-in-touch-left h3 {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 19px;
    text-align: center;
  }
  .get-in-touch-left span {
    font-size: 15px;
    line-height: 192%;
    text-align: center;
  }
  .get-in-touch-btn {
    font-size: 15px !important;
    line-height: 20px !important;
    width: 100%;
  }
  .width-100 {
    width: 100%;
  }
  .footer-section {
    padding: 40px 40px 30px;
  }
  .footer-text-center {
    text-align: center;
  }
  .footersocial-icon ul {
    justify-content: space-between;
  }
  .footer-p-text {
    line-height: 30px !important;
  }
}
