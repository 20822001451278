.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  margin: 0px;
  position: relative;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menu-items button .dot-before {
  margin-left: 3px;
}

.menu-items.active > a:after,
.menu-items.active > button:after {
  content: "";
  width: 100%;
  height: 4px;
  display: block;
  position: absolute;
  left: 0px;
  top: 56px;
  border-radius: 5px;
  background: #1a8fe3;
}

.menu-items {
  padding: 0px 59px 0px 0px;
}

.menu-items > a,
.menu-items button {
  text-align: left;
  padding: 0px 0px 0px 0px;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.5);
  position: relative;
}

.nav-menu .menu-items:last-child > a,
.nav-menu .menu-items:last-child button {
  padding-right: 0px;
}
.dropdown .menu-items:last-child > a,
.nav-menu .menu-items:last-child button {
  padding-right: 1rem;
}
.menu-items a:hover,
.menu-items button:hover {
  background-color: transparent;
  color: #1a8fe3;
}

.dropdown {
  position: absolute;
  right: auto;
  left: 0;
  // box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
  //   0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 210px;
  padding: 7px 12px 0px 0px;
  margin: 1px 0px 0px 0px;
  list-style: none;
  // background: #ffffff;
  // border: 1px solid #d6d6d6;
  // border-radius: 5px;
  display: none;
  border-right: 10px transparent;
  background: rgba(0, 30, 51, 0.8);
  border-top: 3px solid #1a8fe3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  border-radius: 5px;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
  margin-left: 2px;
}
.dropdown .menu-items > a,
.dropdown .menu-items button {
  display: flex;
  white-space: nowrap;
  text-align: left;
  padding: 0.7rem 1.3rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  // color: #222222;
  color: #fff;
  background-color: transparent;
}

.dropdown .menu-items a:hover,
.dropdown .menu-items button:hover {
  background-color: transparent;
  color: #1a8fe3;
}

.animated-icon {
  margin-right: 28px;
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .menu-items > a,
  .menu-items button {
    padding: 0px 0px 0px 0px;
    font-size: 18px;
  }
}
@media (min-width: 1600px) {
  .dropdown {
    min-width: 265px;
    padding: 7px 12px 0px 0px;
  }
}
