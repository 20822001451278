.navbar-transparent-fixed {
  background: transparent !important;
  box-shadow: none !important;
  transition: all 0.5s ease !important;
  -webkit-transition: all 0.5s ease !important;
  border-bottom: 1px solid transparent;
  height: 94px;
}

.display-emp button {
  background: #e6b022 !important;
  border-radius: 50px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  color: #123f36 !important;
  line-height: 16px !important;
  height: 50px !important;
  display: inline-block !important;
  padding: 16px 37px !important;
  text-decoration: none !important;
}

.display-emp-mob {
  padding: 20px 20px 0;
  width: 100%;
}

.display-emp-mob button {
  width: 100%;
  background: #e6b022 !important;
  border-radius: 50px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  color: #123f36 !important;
  line-height: 16px !important;
  height: 50px !important;
  display: inline-block !important;
  padding: 16px 37px !important;
  text-decoration: none !important;
}

// .navbar-white-fixed {
//     background: #FFF !important;
//     border-bottom: 1px solid rgba(26, 143, 227, 0.2) !important;
//     box-shadow: 0 1px 12px rgba(26, 143, 227, 0.2) !important;
//     transition: all 0.5s ease !important;
//     -webkit-transition: all 0.5s ease !important;
// }
.navbar-white-fixed {
  background: #fff !important;
  border-bottom: 1px solid rgba(26, 143, 227, 0.2) !important;
  box-shadow: 0 1px 12px rgba(26, 143, 227, 0.2) !important;
  transition: all 0.5s ease !important;
  -webkit-transition: all 0.5s ease !important;
  height: 70px;
}

// .navbar-white-fixed .menus > .menu-items > a {
//     color: rgba(0, 0, 0, 0.5) !important;
// }
// .navbar-white-fixed .menus > .menu-items > a:hover {
//     color: rgba(0, 0, 0, 0.5) !important;
// }
.navbar-inner {
  width: 100%;
}

.navbar-center {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px;
  height: 94px;
}

.profile-nav {
  display: flex;
  align-items: center;
}

.profile-nav > div {
  margin-left: 0px;
}

.search-icon-header {
  position: relative;
  top: 2px;
}

.sidebar-list-text {
  display: flex;
  align-items: center;
}

.sidebar-list-text span {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.5);
}

.sidebar-list-text:hover span {
  color: #1a8fe3;
}

.sidebar-collapse {
  padding: 10px 0px;
}

.subitem-pad-left {
  padding-left: 20px;
}

.sidebar-drawer {
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

.emp-corner-mobile {
  margin: 8px auto 0px;
  border-top: 1px solid #e0dada;
  width: 100%;
  padding: 20px 20px 0px;
  box-sizing: border-box;
}

.emp-corner-mobile button {
  background: rgb(14, 151, 255) !important;
  border-radius: 10px !important;
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  display: block !important;
  margin: 0px 0px !important;
  width: 100% !important;
  text-align: center !important;
  box-shadow: none !important;
  padding: 9px 8px !important;
}

.left-social-mobile ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 8px 0px 0px;
  padding: 10px 0px;
  justify-content: center;
}

.left-social-mobile ul li {
  margin-right: 10px;
  margin-bottom: 10px;
}

.left-social-mobile ul li:last-child {
  margin-right: 0px;
}

.login-btn {
  background-color: rgb(0, 0, 0) !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  padding: 9px 18px 4px !important;
  border-radius: 0px !important;
  border: 1px solid rgb(255, 255, 255) !important;
}

.login-btn:hover {
  background-color: #0e97ff !important;
  border: 1px solid #0e97ff !important;
}

.top-bar-contct {
  background: #1b2132;
  padding: 12px 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 2;
}

.top-bar-contact-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
  align-items: center;
  list-style-type: none;
}

.top-bar-contact-list li {
  margin-right: 20px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: initial;
}

.top-bar-contact-list li:last-child {
  margin-right: 0px !important;
}

.top-bar-contact-list .top-bar-contct-icon {
  margin-right: 10px;
}

.position-fixed {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 5;
}

.header-navbar-subpage {
  border-bottom: 1px solid rgb(14, 151, 255, 0.2);
  box-shadow: 0px 0px 14px rgb(14, 151, 255, 0.09) !important;
}

.sub-page-top-header-spacer {
  height: 138px;
}

.header-navbar-subpage-top {
  background-color: #ffffff !important;
  box-shadow: none !important;
  position: fixed !important;
  left: 0px !important;
  top: 45px !important;
  width: 100% !important;
  transition: all 0.5s ease !important;
  -webkit-transition: all 0.5s ease !important;
  z-index: 50 !important;
}

.header-navbar-subpage-top .navbar-center {
  padding: 12px 0px 8px;
}

.header-navbar-subpage-top-fixed {
  border-bottom: 1px solid rgba(26, 143, 227, 0.2) !important;
  box-shadow: 0 1px 12px rgba(26, 143, 227, 0.2) !important;
}

.header-navbar-subpage .display-emp button {
  background: #ffffff !important;
  border: 1px solid #d8dcdf !important;
  box-shadow: none !important;
}

// .client-padding-0 {
//     padding-left: 0 !important;
//     padding-right: 0 !important;
// }

.drawer-outer {
  padding-left: 20px;
}

.drawer-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 50px;
  border-bottom: 1px solid rgba(0, 192, 173, 0.2);
}

.drawer-logo img {
  width: 40px;
  height: 40px;
}

.close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  background: rgba(0, 192, 173, 0.2);
  border: 1px solid #1a8fe3;
  border-radius: 100%;
  cursor: pointer;
}

.menu-design {
  background: rgba(0, 30, 51, 0.8);
  border-top: 3px solid #1a8fe3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  border-radius: 0px 0px 20px 20px;
  width: 993px;
  height: 535px;
  position: absolute;
  top: 36px;
  left: -303px;
}
// .sidebar-collapse {
//     background: rgba(14,151,255,.04);
// }
.logo-nav-left {
  display: flex;
  align-items: center;
}

.logo-mrgn-right {
  margin-right: 115px;
}

.hire-pro-btn {
  background: #222222 !important;
  border-radius: 10px !important;
  padding: 13px 36px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  text-align: center !important;
  line-height: 20px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.mvp-button {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  margin: 0px;
  position: relative;
}

.mvp-button-hire {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  margin: 0px;
  position: relative;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.menu-items.active > a:after {
  content: "";
  width: 100%;
  height: 4px;
  display: block;
  position: absolute;
  left: 0px;
  top: 43px;
  border-radius: 5px;
  background: #1a8fe3;
}

.menu-items {
  padding: 0px 59px 0px 0px;
}

.menu-items > a {
  text-align: left;
  padding: 0px 0px 0px 0px;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.5);
  position: relative;
}

.mvp-subbox {
  display: none;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0px;
  padding-top: 40px;
  padding-bottom: 40px;
  background: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 4px rgba(0, 192, 173, 0.05);
}
.mvp-subbox-hire {
  display: none;
  width: 70%;
  height: auto;
  position: absolute;
  top: 100%;
  -webkit-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
  left: 50%;
  background: rgba(0, 30, 51, 0.8);
  padding-top: 20px;
  border-top: 3px solid #1a8fe3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 0px 0px 20px 20px;
  padding-bottom: 20px;
}

.mvp-dropdown-item-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 329px;
  height: 113px;
  padding: 15px 40px 15px;
  background: #ffffff;
  border: 1.19192px solid #e8e8e8;
  border-radius: 5.9596px;
  cursor: pointer;
}

.mvp-dropdown-item-box-mob {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 70px;
  padding: 15px 33px 15px;
  background: #ffffff;
  border: 1.19192px solid #e8e8e8;
  border-radius: 5.9596px;
  cursor: pointer;
}

.mvp-sub-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

// .mvp-dropdown-item-box img{
//     width: 52.84px;
//     height: 56.53px;
// }

// .mvp-dropdown-item-box-mob img{
//     width: 80%;
// }

.mvp-dropdown-item-box span:last-child {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #222222;
  width: 151px;
}

.mvp-dropdown-item-box-mob span:last-child {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #222222;
  width: 151px;
}

.header-icon-disc {
  position: relative;
  margin-right: 37px;
}

.header-icon-disc::after {
  position: absolute;
  right: -15px;
  top: 80%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  background: rgba(26, 143, 227, 0.1);
  width: 38px;
  height: 38px;
  border-radius: 100%;
}

.header-icon-disc-last {
  position: relative;
  margin-right: 37px;
}
.tect-box-rad ul li {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 11px;
  position: relative;
  padding-left: 12px;
  list-style-type: none;
}
.tect-box-rad ul li:before {
  content: "";
  width: 6px;
  height: 6px;
  background: #1a8fe3;
  position: absolute;
  left: 0px;
  border-radius: 100%;
  top: 11px;
}
.developmult-icon {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.developmult-icon .ser-icon {
  margin-right: 25px;
  display: inline-flex;
}
.hm-lidup-developmult-content-body .card-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 132%;
  color: #272c2e;
  margin: 0px;
}
.tect-box-rad .card-title {
  margin: 0px;
}
.tect-box-rad {
  padding-top: 20px;
}
.developmult-icon h3 {
  margin: 0px !important;
}

.header-icon-disc-last::after {
  position: absolute;
  right: -15px;
  top: 40%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  background: rgba(26, 143, 227, 0.1);
  width: 38px;
  height: 38px;
  border-radius: 100%;
}

.menu-font-style {
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 1px;
  color: #ffffff;
  padding-left: 53px;
  margin-bottom: 0px;
}

.menu-font-style > li {
  // text-transform: capitalize;
  cursor: pointer;
}

.menu-font-style > li:hover {
  background-color: transparent;
  color: #1a8fe3;
}

@media (max-width: 1280px) {
  .top-bar-contct {
    display: none;
  }

  .sub-page-top-header-spacer {
    height: 74px;
  }

  .header-navbar-subpage-top {
    top: 0px !important;
  }

  .navbar-center {
    padding: 0px 0px 0px !important;
    height: 65px;
  }

  .display-emp {
    display: none;
  }

  .contact-bar-off {
    display: none !important;
  }

  .logo-mrgn-right {
    margin-right: 35px;
  }
  .logo-mrgn-right img {
    width: 170px;
  }
  .nav-menu {
    margin-right: 0px !important;
  }
  .menu-items > a,
  .menu-items button {
    text-align: left;
    padding: 0px 0px 0px 0px;
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
    color: rgba(0, 0, 0, 0.5);
    position: relative;
  }
  .menu-items > a {
    text-align: left;
    padding: 0px 0px 0px 0px;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: rgba(0, 0, 0, 0.5);
    position: relative;
  }
  .menu-items {
    padding: 0px 48px 0px 0px;
  }
  .hire-pro-btn {
    background: #222222 !important;
    border-radius: 10px !important;
    padding: 13px 20px !important;
    font-weight: 700 !important;
    font-size: 13px !important;
    text-align: center !important;
    line-height: 20px !important;
    color: #ffffff !important;
    text-transform: capitalize !important;
  }
}
@media (max-width: 1199px) {
  .navbar-transparent {
    background: rgba(0, 0, 0, 0.69) !important;
    box-shadow: none !important;
  }

  .login-btn {
    font-size: 12px !important;
    padding: 9px 12px 6px !important;
  }
}
@media (max-width: 767px) {
  .navbar-white-fixed {
    height: 54px;
  }
}
@media (min-width: 1400px) {
  .MuiContainer-maxWidthLg {
    max-width: 1320px !important;
  }
}
