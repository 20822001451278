.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.ms-auto {
  margin-left: auto !important;
}

.pdding-top0 {
  padding-top: 0px !important;
}

.img-full {
  width: 100% !important;
}

.object-fit {
  object-fit: cover !important;
}

.positionAbsolute {
  position: absolute;
}

.positionRelative {
  position: relative;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.display-block {
  display: block !important;
}

.left-right-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.text-center {
  text-align: center !important;
}

.cover-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px;
}

.cursor-pointer {
  cursor: pointer;
}

.bredcum {
  margin-top: 30px;
}

.bredcum li {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7b7b7b;
}

.bredcum li a {
  text-decoration: none;
  color: inherit;
}

.bredcum-active {
  color: #0e97ff;
}

.ellipses-2line-text {
  text-overflow: ellipsis !important;
  word-break: break-word;
  overflow: hidden;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.ellipses-line-text {
  text-overflow: ellipsis !important;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.theme-primary-color {
  color: #0e97ff;
}

.document-modal-close {
  position: absolute;
  right: -50px;
  top: -2px;
  cursor: pointer;
}

.text-modal-close {
  position: absolute;
  right: -50px;
  top: -2px;
  cursor: pointer;
}

.image-modal-close {
  position: absolute;
  right: -11px;
  top: 27px;
  cursor: pointer;
}

.share-modal-close {
  position: absolute;
  right: -6px;
  top: -17px;
  cursor: pointer;
}

.form-modal-close {
  position: absolute;
  right: -45px;
  top: 0px;
  cursor: pointer;
  z-index: 44;
}

.text-modal-paper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
}

.text-modal-paper-inner {
  background-color: #ffffff;
  border: none !important;
  outline: none !important;
  border-radius: 15px;
  box-shadow: 0px 8px 8px rgb(0 0 0 / 10%);
  padding: 60px;
  box-sizing: border-box;
}

.default-radius-img {
  border-radius: 15px;
}

.main-section-height {
  height: 94px;
}

.accordin-content {
  padding: 10px 32px 0;
}

.paragraph-accordin {
  font-weight: 400;
  font-size: 16px;
  line-height: 38px;
  letter-spacing: 1px;
  color: #ffffff;
  margin-top: 0;
}

.bg-banner-padding {
  padding: 0px 30px;
}

.iframe-height {
  margin: 69px auto 0px;
  width: 100%;
  height: 990px;
}

@media (max-width: 1280px) {
  .main-section-height {
    height: 64px;
  }
}
@media (max-width: 1199px) {
  .iframe-height {
    height:1100px;
}
}
@media (max-width: 1100px) {
  .iframe-height {
    margin: 53px auto 0px;
}
}

@media (max-width: 767px) {
  .bredcum {
    margin-top: 20px;
  }
  .iframe-height {
    margin: 53px auto 0px;
}
  .padd-mb-off {
    padding-left: 0px;
    padding-right: 0px;
  }

  .text-modal-close {
    right: -27px;
    top: -18px;
  }

  .form-modal-close {
    right: -27px;
    top: -18px;
  }

  .text-modal-paper-inner {
    padding: 20px;
  }

  .text-modal-paper-inner h3 {
    font-size: 17px;
  }

  .text-modal-paper-inner h4 {
    font-size: 15px;
  }

  .text-modal-paper-inner p,
  .text-modal-paper-inner li,
  .text-modal-paper-inner i {
    font-size: 13px;
    line-height: 25px;
  }

  .paragraph-accordin {
    font-size: 12px;
    line-height: 26px;
  }

  .accordin-content {
    padding: 6px 6px 0;
  }

  .bg-banner-padding {
    padding: 0px 10px;
  }
}

@media (min-width: 1600px) {
  .bg-banner-padding {
    padding: 0px 100px;
  }
}